import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/Seo"
import {
  PrimaryHero,
  HeroCopyBlock
} from "../../../components/Heros"
import OurClients from "../../../components/OurClients"
import { DualImageBlock } from "../../../components/Grid"
import Button from "../../../components/Button"
import OurCarriers from "../../../components/OurCarriers"
import RequestDemo from "../../../components/RequestDemo"

import cx from "classnames"
import styles from "./styles.module.scss"

import messageIcon from "./images/default.svg"
import tickPurple from "./images/tickPurple.svg"
import tickWhite from "./images/tickWhite.svg"
import coffeeImg from "./images/coffee.png"
import fatherDaughterImg from "./images/fatherdaughter.png"
import mobiledeviceImg from "./images/mobiledevice.png"
import { Link } from "gatsby";
import onphoneImg from "./images/onphone.png"

const Hero = () => (
  <Layout>
    <Seo title="Sorted | Products - Hero" keywords={[`Sorted`, `Heroducts`, `Hero`]} />
    <PrimaryHero theme="pink">
      <HeroCopyBlock title={["Delivery options", "for the dynamic."]}>
        <p>The reliable DC operations dashboard, with scalable, single-integration carrier cloud, consignment allocation and label printing. Liberating you from IT constraints, making your logistics easier and more efficient.</p>
      </HeroCopyBlock>
    </PrimaryHero>
    <div className={cx("container", styles.clientImages)}>
      <OurClients />
    </div>

    <div className="bg-light">
      <div className="container">
        <div className={styles.productPoints}>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Connects to your existing webpage.</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Real-time carrier service availability check.</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Retailer shipping policy integration.</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>C&amp;C location capacity checks.</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Subset of sortedPRO API's.</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Full documentation portal.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={styles.genericSpacer}>
      <DualImageBlock img={coffeeImg} theme="white" maxHeight={700}>
        <div>
          <h3>It’s not ‘one size fits all’<br/>on your web front end.</h3>
          <p>Having a multi-service delivery proposition is hygiene. Having a dynamic and personalised delivery proposition is market-leading.</p>
          <div className={styles.tickPoints}>
            <div className={styles.tickPoint}>
              <img src={tickPurple} alt="purple tick"/>
              <div>
                <p><b>Competitive advantage</b></p>
                <p>Leap ahead in your industry. Differentiate on how you serve your customers.</p>
              </div>
            </div>
            <div className={styles.tickPoint}>
              <img src={tickPurple} alt="purple tick"/>
              <div>
                <p><b>Upgrade offering</b></p>
                <p>With one single API integration to access HERO functionality.</p>
              </div>
            </div>
            <div className={styles.tickPoint}>
              <img src={tickPurple} alt="purple tick"/>
              <div>
                <p><b>Customer optimised options</b></p>
                <p>3000+ carrier services; including timed, nominated day, next day and click & collect.</p>
              </div>
            </div>
          </div>
          <Button theme="purple"><a href="https://www.test.com">Learn more</a></Button>
        </div>
      </DualImageBlock>
    </div>

    <div className={styles.genericSpacer}>
      <div class="container-fluid">
        <DualImageBlock img={fatherDaughterImg} reverse theme="light" maxHeight={700}>
          <div>
            <h3>Beautiful, functional and tailor-made.</h3>
            <p>Own every single word your customer sees at checkout, and ensure what they’re seeing gives them the choice and convenience they crave.</p>
            <div className={styles.tickPoints}>
              <div className={styles.tickPoint}>
                <img src={tickPurple} alt="purple tick"/>
                <div>
                  <p><b>Control tone of voice</b></p>
                  <p>Fully own brand presentation within delivery offering.</p>
                </div>
              </div>
              <div className={styles.tickPoint}>
                <img src={tickPurple} alt="purple tick"/>
                <div>
                  <p><b>Integration in your own hands</b></p>
                  <p>No need to rely on IT or Operations to improve carrier proposition.</p>
                </div>
              </div>
              <div className={styles.tickPoint}>
                <img src={tickPurple} alt="purple tick"/>
                <div>
                  <p><b>Make realistic customer promises</b></p>
                  <p>Scale-proof tools to ensure you make arrangements your DC can fulfil.</p>
                </div>
              </div>
            </div>
            <Button theme="purple"><a href="https://www.test.com">Learn more</a></Button>
          </div>
        </DualImageBlock>
      </div>
    </div>

    <div className={styles.genericSpacer}>
      <div className={styles.mobileBlock}>
        <div className={styles.mobileBlockOverlay} />
        <div className="container">
          <div className={styles.mobileBlockHeader}>
            <div className={styles.mobileBlockHeaderImg}>
              <img src={mobiledeviceImg} alt="phone using Sorted.Hero" />
            </div>
            <div className={styles.mobileBlockHeaderTicks}>
              <div className={styles.tickPoints}>
                <div className={styles.tickPoint}>
                  <img src={tickPurple} alt="purple tick"/>
                  <div>
                    <p><b>Keep and convert customers</b></p>
                    <p>Decrease basket abandonment, increase conversion. Don’t make delivery a reason to drop-out.</p>
                  </div>
                </div>
                <div className={styles.tickPoint}>
                  <img src={tickPurple} alt="purple tick"/>
                  <div>
                    <p><b>First time delivery success</b></p>
                    <p>When customers get convenient options, you guarantee they get what they want.</p>
                  </div>
                </div>
                <div className={styles.tickPoint}>
                  <img src={tickPurple} alt="purple tick"/>
                  <div>
                    <p><b>Loyalty and retention</b></p>
                    <p>Making acquisition costs lower.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mobileBlockCopy}>
          <div className="container-thin">
            <div className={styles.mobileBlockCopyInner}>
              <div>
                <h3>KPIs and ROIs.</h3>
                <p>The retailer shouldn’t dictate delivery, consumers should be listened to. That’s what keeps your customers happy and your numbers positive.</p>
              </div>
              <div>
                <Button theme="white"><a href="https://www.test.com">Learn more</a></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={styles.genericSpacer}>
      <div className="container-large-fluid">
        <div className={styles.triImageBlock}>
          <div className={styles.triImageBlockLeft}>
            <div>
              <h3>Clever data.</h3>
              <p>The amalgamation of data is simplified with HERO, smoothing out how you access and surface optimised carrier, warehouse and delivery information.</p>
            </div>
            <div>
              <Button theme="purple"><a href="#">Learn more</a></Button>
            </div>
          </div>
          <div className={styles.triImageBlockRight}>
            <div className={styles.triImageBlockImg}>
              <img src={onphoneImg} alt="woman on phone"/>
              <div className={styles.tickPointTri}>
                <img src={tickWhite} alt="purple tick"/>
                <div>
                  <p><b>Connecting warehouse and checkout</b></p>
                  <p>Remove complexities of working between business areas.</p>
                </div>
              </div>
            </div>
            <div className={styles.triImageBlockDark}>
              <div className={styles.tickPointTri}>
                <img src={tickWhite} alt="purple tick"/>
                <div>
                  <p><b>Market trends</b></p>
                  <p>Evolve by being quick to act to optimise fulfilment.</p>
                </div>
              </div>
            </div>
            <div className={styles.triImageBlockLight}>
              <div className={styles.tickPointTri}>
                <img src={tickPurple} alt="purple tick"/>
                <div>
                  <p><b>Lead your brand</b></p>
                  <p>Bring the solution that the business is looking for.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={styles.genericSpacer}>
      <div class="container">
        <h3 className="text-pink text-center">Our carrier cloud.</h3>
        <p className="text-center">One single integration with Sorted connects you to hundreds of services from some of the biggest global carriers.</p>
        <OurCarriers />
        <div className="text-center">
          <Button theme="greyoutline"><Link to="/carriers">Learn more about carriers</Link></Button>
        </div>
      </div>
    </div>

    <RequestDemo />

  </Layout>
)

export default Hero
