import React from "react"
import asos from "./images/ASOS.svg"
import feelunique from "./images/FeelUnique.svg"
import footasylum from "./images/Footasylum.svg"
import frenchconnection from "./images/FrenchConnection.svg"
import grahambrown from "./images/grahamBrown.svg"
import hotter from "./images/hotter.svg"
import lush from "./images/LUSH.svg"
import missguided from "./images/Missguided.svg"
import mountainwarehouse from "./images/MountainWarehouse.svg"
import wincanton from "./images/wincanton.svg"

import styles from "./styles.module.scss"

const OurClients = () => (
  <div className={styles.clientImages}>
    <img alt="client logo" className={styles.asos}src={asos} />
    <img alt="client logo" className={styles.missguided}src={missguided} />
    <img alt="client logo" className={styles.grahambrown}src={grahambrown} />
    <img alt="client logo" className={styles.frenchconnection}src={frenchconnection} />
    <img alt="client logo" className={styles.lush}src={lush} />
    <img alt="client logo" className={styles.feelunique}src={feelunique} />
    <img alt="client logo" className={styles.hotter}src={hotter} />
    <img alt="client logo" className={styles.mountainwarehouse}src={mountainwarehouse} />
    <img alt="client logo" className={styles.footasylum}src={footasylum} />
    <img alt="client logo" className={styles.wincanton}src={wincanton} />
  </div>
)

export default OurClients
